body {
  margin: 0;
  min-height:100vh !important;
  width: 100%;
  padding: 0;
}

@font-face {
  font-family: 'Inter';
  src: url("./fonts/Inter-Regular.ttf")
}
@font-face {
  font-family: 'Inter-black';
  src: url("./fonts/Inter-Black.ttf")
}
@font-face {
  font-family: 'Inter-extra';
  src: url("./fonts/Inter-ExtraBold.ttf")
}
@font-face {
  font-family: 'Inter-light';
  src: url("./fonts/Inter-ExtraLight.ttf")
}
@font-face {
  font-family: 'Inter-thin';
  src: url("./fonts/Inter-Thin.ttf")
}
@font-face {
  font-family: 'Harmonia';
  src: url("./fonts/Harmonia-regular.otf")
}
@font-face {
  font-family: 'Poppins';
  src: url("./fonts/Poppins-SemiBold.ttf")
}
