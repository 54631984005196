.myswiper {
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
}

.scontainer{
width: 100% !important;
}
.schild{
    width: 100% !important;
}
 

.bullet{
    background: #fff !important;
}
:root --swiper-pagination{
    height: 50px !important;
    width: 50px !important;
    background: yellow !important;
}
.bulles{
    border: 5px solid red !important;
}

@media (max-width: 700px) {
    :root {
        --swiper-navigation-color: #005591;
        --swiper-pagination-color: #005591 !important;
    } 

}

@media (min-width: 701px) and (max-width: 1920px) {
    :root {
        --swiper-navigation-color: #fff;
        --swiper-pagination-color: #005591 !important;
    } 
  }