.inputs:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.inputs{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.inputCard{
  outline: none !important;
  border: none !important;
  border-radius: 0px !important;
  border: 1px dashed #fff !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vid{
  width: 100%;
  min-height: 200px;
}

@media screen and (min-width:1020px) and (max-width:1590px){
  .container {
    width: 91% !important;
}
  .chatput{
    width: 300px !important;
  }
}


  @media screen and (min-width:1600px) and (max-width:1920px){
    .container {
        width: 65% !important;
       
      
    }
    .chatput{
      width: 250px !important;
    }
  }
  @media screen and (min-width:1920px){
    .container {
        width: 62% !important;
    }
    .fontS{
      font-size: 28px !important;
    }
    .fontSm{
      font-size: 14px !important;
    }
 
  }